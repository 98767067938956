import * as React from 'react';
const SvgGift = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" {...props}>
        <path fill="none" d="M0 0h20v20H0z" data-name="Rectangle 358" />
        <g data-name="Group 417">
            <path
                fillRule="evenodd"
                d="M14.9 5.582a2.736 2.736 0 0 1-.214 1.106h1.264A1.079 1.079 0 0 1 17 7.794v1.844H3V7.794a1.079 1.079 0 0 1 1.05-1.106h1.264A2.737 2.737 0 0 1 5.1 5.582 2.607 2.607 0 0 1 7.738 3 2.965 2.965 0 0 1 10 4.128 2.965 2.965 0 0 1 12.262 3 2.607 2.607 0 0 1 14.9 5.582Zm-8.4 0a1.176 1.176 0 0 1 1.238-1.107A1.646 1.646 0 0 1 9.3 6.2v.492h-.592A4.706 4.706 0 0 1 7.094 6.5a.933.933 0 0 1-.435-.3 1 1 0 0 1-.159-.618Zm4.792 1.106H10.7V6.2a1.646 1.646 0 0 1 1.562-1.725A1.176 1.176 0 0 1 13.5 5.582a1 1 0 0 1-.159.617.933.933 0 0 1-.435.3 4.706 4.706 0 0 1-1.614.189Z"
                data-name="Path 374"
            />
            <path d="M9.307 16.999v-5.9H3.701v4.794a1.078 1.078 0 0 0 1.051 1.106Z" data-name="Path 375" />
            <path d="M10.693 16.999v-5.9h5.606v4.794a1.078 1.078 0 0 1-1.051 1.106Z" data-name="Path 376" />
        </g>
    </svg>
);
export default SvgGift;
