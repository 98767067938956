/* eslint-disable import/prefer-default-export */
const { features } = require('../../swift.config');

const getThumborUrl = () => {
    const appEnv = typeof window !== 'undefined' ? window.APP_ENV : process.env.APP_ENV;
    const thumborUrl = features.thumbor.thumborUrl[appEnv] || features.thumbor.thumborUrl.prod;
    return thumborUrl;
};

module.exports = { getThumborUrl };
