/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link';
import { SECONDARY, GRAY_25 } from '@src_theme/colors';
import Phone from '@src_theme/icons/Phone';
import Location from '@src_theme/icons/Location';
import Gift from '@src_theme/icons/Gift';
import ContentBoundary from '@commons/ContentBoundary';
import makeStyles from '@material-ui/core/styles/makeStyles';
import dynamic from 'next/dynamic';

const LanguageSelect = dynamic(() => import('./LanguageSelect'), { ssr: false });

const useStyles = makeStyles({
    root: {
        backgroundColor: SECONDARY,
    },
    ul: {
        display: 'flex',
        margin: 0,
        padding: 0,
        listStyle: 'none',
        color: GRAY_25,
        fontSize: 12,
        '& li, & li a': {
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            height: 32,
        },
        '& li:first-child': {
            marginRight: 'auto',
        },
        '& li:nth-child(2)': {
            marginRight: 26,
        },
        '& li:nth-child(3)': {
            marginRight: 31.5,
        },
    },
});

const ViewTop = (props) => {
    const { t, isLogin } = props;
    const styles = useStyles();

    return (
        <div className={styles.root}>
            <ContentBoundary>
                <ul className={styles.ul}>
                    <li>
                        <Phone width={24} />
                        <span>Hotline 0878 0002 1010</span>
                    </li>
                    <li>
                        <Link href="/sales/order/track" prefetch={false}>
                            <a className="tracking-order">
                                <Location width={20} />
                                {t('common:menu:trackingorder')}
                            </a>
                        </Link>
                    </li>
                    {!isLogin && (
                        <li>
                            <Link href="/customer/account/register" prefetch={false}>
                                <a className="register-member">
                                    <Gift width={20} />
                                    {t('common:menu:registerMember')}
                                </a>
                            </Link>
                        </li>
                    )}
                    <li>
                        <LanguageSelect />
                    </li>
                </ul>
            </ContentBoundary>
        </div>
    );
};
export default ViewTop;
