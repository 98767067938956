import * as React from 'react';
const SvgPhone = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" {...props}>
        <path fill="none" d="M0 0h20v20H0z" data-name="Rectangle 355" />
        <path
            strokeWidth={0.5}
            d="M9.647 7.23 9.2 5a1.25 1.25 0 0 0-1.226-1H5.282A1.284 1.284 0 0 0 4 5.322c.384 8.468 4.582 11.688 12.012 12.011a1.283 1.283 0 0 0 1.32-1.279v-2.7a1.25 1.25 0 0 0-1.005-1.226l-2.227-.441a1.25 1.25 0 0 0-1.363.667l-.407.813c-1.667 0-4.167-2.5-4.167-4.167l.813-.407a1.248 1.248 0 0 0 .671-1.363Z"
            data-name="Path 372"
        />
    </svg>
);
export default SvgPhone;
